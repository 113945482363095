export const PR_REPORT_URLS = [
  '/en/pr-report-intro/',
  '/en/pr-report-chapter-one/',
  '/en/pr-report-chapter-two/',
  '/en/pr-report-chapter-three/',
  '/en/pr-report-chapter-four/',
  '/en/pr-report-chapter-five/',
  '/en/pr-report-summary/',
  '/en/pr-insights-2025-impostor-syndrome/',
  '/en/pr-insights-2025/',
  '/en/pr-insights-2025-moral-dilemmas/',
  '/en/pr-insights-2025-people/',
  '/en/pr-insights-2025-summary/',
  '/en/pr-insights-2025-uncertainties/',
  '/en/pr-insights-2025-rejection/',
];
