'use client';

import { MEDIA_LARGE, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { Chart, H2, P } from '../ui';

const Article = styled.article`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
`;

const Section = styled.section`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.div`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourChapterOne: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChOneSectionFourDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  chartHeader: string | null;
  chart: any;
}> = ({ header, description, chartHeader, chart }) => {
  return (
    <Container>
      <Section>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
        <Chart header={chartHeader} chart={chart} />
      </Section>
    </Container>
  );
};
