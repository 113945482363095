'use client';

import {
  MEDIA_LARGE,
  RED_100,
  RED_700,
  SIZING_14,
  SPACING_12,
  SPACING_6,
  SPACING_8,
} from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { H2, P } from '../ui';

const H3 = styled.h3`
  margin: 0;
  line-height: 1;
  font-weight: 900;
  font-size: ${SIZING_14};
`;

const Card = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 24px;
  padding: 5rem 2.5rem;
  row-gap: ${SPACING_6};
  flex-direction: column;
  color: ${RED_700};
  justify-content: center;
  background-color: ${RED_100};
`;

const Cards = styled.div`
  display: flex;
  row-gap: 4rem;
  flex-direction: column;
`;

const Article = styled.article`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
  max-height: fit-content;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(45% - 86.5px);
  }
`;

const Section = styled.div`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionTwoChapterFive: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionTwoDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  stats:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFiveSectionTwoStats';
        percentage: number | null;
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFiveSectionTwoStatsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ header, description, stats }) => {
  return (
    <Container>
      <Section>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </Article>
        <Cards>
          {stats?.map((item, i) => (
            <Card key={i}>
              <H3>{item?.percentage}%</H3>
              <P>
                {item?.paragraphs?.map((paragraph, k) => (
                  <Fragment key={k}>
                    {paragraph?.paragraphBold?.length && paragraph?.paragraphBold[0] === 'bold' ? (
                      <b>{paragraph?.paragraph}</b>
                    ) : (
                      <>{paragraph?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </P>
            </Card>
          ))}
        </Cards>
      </Section>
    </Container>
  );
};
