'use client';

import { MEDIA_LARGE, NEUTRAL_WHITE, RED_100, RED_700, SPACING_3 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';
import { H2, H4, P, Tag } from '../ui';

const Wrapper = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Section = styled.section`
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  row-gap: ${SPACING_3};
  flex-direction: column;
`;

const Container = styled.div`
  background-color: ${NEUTRAL_WHITE};
  @media (min-width: ${MEDIA_LARGE}px) {
    margin-top: -10rem;
    padding: 10rem 5rem 0 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem 0 2rem;
  }
`;

export const SectionThreeIntro: React.FC<{
  tag: string | null;
  header: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionThreeList';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ tag, header, list }) => {
  return (
    <Container>
      <Section>
        <Tag $color={RED_700} $backgroundColor={RED_100}>
          {tag?.toUpperCase()}
        </Tag>
        <H2>{header}</H2>
        {list?.map((item: any, index: number) => (
          <Wrapper key={index}>
            <H4>{item.header}</H4>
            <P>{item.description}</P>
          </Wrapper>
        ))}
      </Section>
    </Container>
  );
};
