'use client';

import {
  MEDIA_LARGE,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_12,
  SPACING_8,
} from '@mnd-frontend/ui';
import styled from 'styled-components';
import { H4, P, Tag } from '../ui';

const Author = styled.div<{ $imageUrl: string; $size: string; $position: string }>`
  display: flex;
  padding: 2rem;
  min-height: 500px;
  border-radius: 24px;
  justify-content: end;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(${props => props.$imageUrl});
  background-size: ${props => props.$size};
  background-position: ${props => props.$position};
  background-repeat: no-repeat;
`;

const Card = styled.div`
  color: ${NEUTRAL_WHITE};
`;

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
  margin: 4rem 0 0 0;
`;

const H3 = styled.h3`
  opacity: 0.85;
  font-size: 1.45rem;
  margin: 0.5rem 0 1.5rem 0;
`;

const Section = styled.section`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.div`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveChapterOne: React.FC<{
  tag: string | null;
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChOneSectionFiveDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quote: string | null;
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorDescription: string | null;
  authorSize: string | null;
  authorPosition: string | null;
}> = ({
  tag,
  header,
  description,
  quote,
  authorImage,
  authorName,
  authorDescription,
  authorSize,
  authorPosition,
}) => {
  return (
    <Container>
      <Section>
        <article>
          <Tag $color={NEUTRAL_WHITE} $backgroundColor={RED_700}>
            {tag?.toUpperCase()}
          </Tag>
          <H3>{header}</H3>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
          <Blockquote>
            <Quote>“</Quote>
            <i>{quote}</i>
          </Blockquote>
        </article>
        <Card>
          <Author
            $imageUrl={authorImage?.mediaItemUrl ?? ''}
            $position={authorPosition ?? ''}
            $size={authorSize ?? ''}
          >
            <H4>{authorName}</H4>
            <P>{authorDescription}</P>
          </Author>
        </Card>
      </Section>
    </Container>
  );
};
