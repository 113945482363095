'use client';

import { Icons, MEDIA_LARGE, RED_600, RED_700, SPACING_12, SPACING_8 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const P = styled.p`
  margin-top: 0.15rem;
  font-size: 1.15rem;
  max-width: 800px;
`;

const LI = styled.li`
  display: flex;
  column-gap: 1.5rem;
`;

const UL = styled.ul`
  gap: 2rem;
  padding: 0;
  display: flex;
  list-style: none;
  flex-direction: column;
`;

const Span = styled.span`
  color: ${RED_600};
  font-size: 1.75rem;
`;

const H2 = styled.h2`
  line-height: 1;
  color: ${RED_700};
  font-size: 2.75rem;
`;

const Section = styled.section`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.div`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionNineChapterFour: React.FC<{
  subheader: string | null;
  header: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChFourSectionNineParagraphs';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChFourSectionNineParagraphsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ subheader, header, list }) => {
  return (
    <Container>
      <Section>
        <H2>
          <Span>{subheader}</Span> <br /> {header}
        </H2>
        <UL>
          {list?.map((item, i) => (
            <LI key={i}>
              <div>
                <Icons.Check $size="md" $color="RED_700" />
              </div>
              <P>
                {item?.paragraphs?.map((text, k) => (
                  <Fragment key={k}>
                    {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                      <b>{text?.paragraph}</b>
                    ) : (
                      <>{text?.paragraph}</>
                    )}
                  </Fragment>
                ))}
              </P>
            </LI>
          ))}
        </UL>
      </Section>
    </Container>
  );
};
