'use client';

import {
  GREY_1000,
  Icons,
  InfoModal,
  MEDIA_LARGE,
  MEDIA_SMALL,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_12,
  SPACING_8,
} from '@mnd-frontend/ui';
import { Fragment, useState } from 'react';
import styled from 'styled-components';
import { H4, P, Tag } from '../ui';

const Description = styled.div`
  display: flex;
  row-gap: 0.5rem;
  flex-direction: column;
`;

const Minutes = styled.div`
  padding: 1rem;
  background-color: ${GREY_1000};
`;

const Play = styled.div`
  padding: 1rem;
  background-color: ${RED_700};
`;

const Cta = styled.div`
  display: flex;
  overflow: hidden;
  border-radius: 8px;
  align-items: center;
`;

const Info = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: ${MEDIA_SMALL}px) {
    row-gap: 1rem;
    align-items: start;
    flex-direction: column;
    justify-content: start;
  }
`;

const Author = styled.div<{ $imageUrl: string }>`
  display: flex;
  padding: 2rem;
  min-height: 500px;
  justify-content: end;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(${props => props.$imageUrl});
  background-position: 50% 5%;
  background-repeat: no-repeat;
  @media (min-width: ${MEDIA_LARGE}px) {
    background-size: 100%;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    background-size: 140%;
  }
`;

const Overlay = styled.button`
  inset: 0;
  opacity: 0;
  z-index: 10;
  cursor: pointer;
  position: absolute;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  background-color: transparent;
  &:hover {
    opacity: 1;
    background-color: rgba(0, 0, 0, 0.5);
  }
`;

const Card = styled.div`
  overflow: hidden;
  position: relative;
  height: fit-content;
  border-radius: 24px;
  color: ${NEUTRAL_WHITE};
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 1.5rem;
  overflow: hidden;
  height: fit-content;
  flex-direction: column;
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(30% - 86.5px);
  }
`;

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  margin: 4rem 0;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
`;

const H3 = styled.h3`
  opacity: 0.85;
  font-size: 1.45rem;
  margin: 0.5rem 0 1.5rem 0;
`;

const Section = styled.section`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.div`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFiveChapterTwo: React.FC<{
  tag: string | null;
  header: string | null;
  description: string | null;
  quote: string | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionFiveParagraphs';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrChTwoSectionFiveParagraphsParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
  cardDescription:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChTwoSectionFiveCardDescription';
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  authorImage:
    | {
        __typename?: 'MediaItem';
        altText: string | null;
        mediaItemUrl: string | null;
      }
    | undefined;
  authorName: string | null;
  authorDescription: string | null;
}> = ({
  tag,
  header,
  description,
  quote,
  list,
  cardDescription,
  authorImage,
  authorName,
  authorDescription,
}) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <InfoModal
        pillText="Work in progress"
        size="lg"
        description="Info modal using iframe"
        onClose={() => setOpen(false)}
        open={open}
        media={<iframe src="https://www.youtube.com/embed/tgbNymZ7vqY"></iframe>}
        headline="Example:"
      />
      <Container>
        <Section>
          <Wrapper>
            <Card>
              <Overlay type="button" onClick={() => setOpen(true)} />
              <Author $imageUrl={authorImage?.mediaItemUrl ?? ''}>
                <Info>
                  <div>
                    <H4>{authorName}</H4>
                    <P>{authorDescription}</P>
                  </div>
                  <Cta>
                    <Play>
                      <Icons.Play $color="NEUTRAL_WHITE" />
                    </Play>
                    <Minutes>
                      <H4>2 min</H4>
                    </Minutes>
                  </Cta>
                </Info>
              </Author>
            </Card>
            <P>
              {cardDescription?.map((item, index) => (
                <Fragment key={index}>
                  {item?.paragraphBold?.length && item?.paragraphBold[0] === 'bold' ? (
                    <b>{item?.paragraph}</b>
                  ) : (
                    <>{item?.paragraph}</>
                  )}
                </Fragment>
              ))}
            </P>
          </Wrapper>
          <article>
            <Tag $color={RED_700} $backgroundColor={RED_100}>
              {tag?.toUpperCase()}
            </Tag>
            <H3>{header}</H3>
            <P>
              <b>{description}</b>
            </P>
            <Blockquote>
              <Quote>“</Quote>
              <i>{quote}</i>
            </Blockquote>
            <Description>
              {list?.map((item, i) => (
                <P key={i}>
                  {item?.paragraphs?.map((text, k) => (
                    <Fragment key={k}>
                      {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                        <b>{text?.paragraph}</b>
                      ) : (
                        <>{text?.paragraph}</>
                      )}
                    </Fragment>
                  ))}
                </P>
              ))}
            </Description>
          </article>
        </Section>
      </Container>
    </>
  );
};
