'use client';

import {
  Button,
  Icons,
  MEDIA_LARGE,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_3,
  Text,
} from '@mnd-frontend/ui';
import { useState } from 'react';
import { styled } from 'styled-components';
import { Tag } from '../ui';

const P = styled.p`
  opacity: 0.85;
  line-height: 1;
  font-size: 1.1rem;
`;

const H3 = styled.h3`
  opacity: 0.85;
  font-size: 1.25rem;
  margin: 1.5rem 0 0.5rem 0;
`;

const Section = styled.section`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 6rem 2rem 14rem 2rem;
  }
  padding: 6rem 2rem 18rem 2rem;
`;

const Wrapper = styled.div`
  display: flex;
  margin: 0 auto;
  max-width: 1280px;
  position: relative;
  row-gap: ${SPACING_3};
  flex-direction: column;
`;

const Ul = styled.ul`
  gap: 2rem;
  margin: 0;
  padding: 0;
  display: grid;
  list-style: none;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  @media (min-width: ${MEDIA_LARGE}px) {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
`;

const Figure = styled.figure`
  margin: 0;
  padding: 0;
`;

const ImgWrapper = styled.div`
  overflow: hidden;
  position: relative;
  border-radius: 24px;
  aspect-ratio: 1 / 1;
  i {
    top: 50%;
    left: 50%;
    opacity: 0;
    z-index: 2;
    position: absolute;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
  }
  &:after {
    content: '';
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity 0.3s ease;
  }
  img {
    max-width: 100%;
  }
`;

const ExpandButton = styled.button`
  all: unset;
  cursor: pointer;

  &:hover ${ImgWrapper}:after {
    opacity: 1;
  }
  &:hover i {
    opacity: 1;
  }
`;

const Expert = styled.article<{ $show: boolean }>`
  top: 68px;
  gap: 1rem;
  z-index: 2;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  visibility: ${({ $show }) => ($show ? 'visible' : 'hidden')};
  position: absolute;
  background: ${NEUTRAL_WHITE};
  padding: 0;
  height: 100%;
  button {
    color: white;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
  }
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: 2rem;
    grid-template-rows: unset;
    grid-template-columns: auto 1fr;
    button {
      color: inherit;
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
`;

const ExpertImgWrapper = styled.div`
  height: fit-content;
  @media (min-width: ${MEDIA_LARGE}px) {
    background: transparent;
    justify-self: end;
  }
  img {
    width: 100%;
  }
`;

const ExpertDetails = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  padding: 0;
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 2rem 0;
    width: 90%;
  }
`;

export const SectionFourIntro: React.FC<{
  tag: string | null;
  experts:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionFourExperts';
        aspectRatio: number | null;
        profession: string | null;
        description: string | null;
        marginTop: string | null;
        name: string | null;
        scale: number | null;
        image: {
          __typename?: 'AcfMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            altText: string | null;
            mediaItemUrl: string | null;
          };
        } | null;
      } | null)[]
    | null;
}> = ({ tag, experts }) => {
  const [showExpert, setShowExpert] = useState<number | null>(null);

  return (
    <Section>
      <Wrapper>
        <Tag as="h2" $color={RED_700} $backgroundColor={RED_100}>
          {tag?.toUpperCase()}
        </Tag>
        <Ul>
          {experts?.map((item, index) => {
            return (
              <li key={index}>
                <ExpandButton
                  type="button"
                  onClick={() => setShowExpert(index)}
                  aria-expanded={showExpert === index}
                  aria-controls={`expert-details-${index}`}
                  aria-label={`Expand for more details`}
                >
                  <Figure>
                    <ImgWrapper>
                      <Icons.Expand $color="NEUTRAL_WHITE" $size="sm" />
                      <img
                        src={item?.image?.node.mediaItemUrl ?? ''}
                        alt={item?.image?.node.altText ?? ''}
                      />
                    </ImgWrapper>
                    <figcaption>
                      <H3>{item?.name}</H3>
                      <P>{item?.profession}</P>
                    </figcaption>
                  </Figure>
                </ExpandButton>
              </li>
            );
          })}
        </Ul>
        <Expert id={`expert-details-${showExpert}`} $show={showExpert !== null}>
          <Button
            type="button"
            onClick={() => setShowExpert(null)}
            configuration="text"
            icon={<Icons.Close $size="sm" />}
            aria-label="Close expert detials card"
          />
          {showExpert !== null && experts && experts.length && (
            <ExpertImgWrapper>
              <img src={experts[showExpert]?.image?.node.mediaItemUrl ?? undefined} alt="" />
            </ExpertImgWrapper>
          )}
          <ExpertDetails>
            <Text as="h3" variant="headlineLarge">
              {showExpert !== null ? experts?.[showExpert]?.name : ''}
            </Text>
            <Text as="span" variant="subheading">
              {showExpert !== null ? experts?.[showExpert]?.profession : ''}
            </Text>
            <Text as="p" variant="body">
              {showExpert !== null ? experts?.[showExpert]?.description : ''}
            </Text>
          </ExpertDetails>
        </Expert>
      </Wrapper>
    </Section>
  );
};
