'use client';

import { PURPLE_100, PURPLE_700, RED_100, RED_700 } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';

const Small = styled.small`
  margin: auto 0;
  font-size: 1rem;
`;

const Span = styled.span<{ $color: string }>`
  font-weight: 900;
  font-size: 1.45rem;
  color: ${props => props.$color};
`;

const H4 = styled.h4`
  margin: 0 0 1rem 0;
  font-size: 1.15rem;
`;

const Row = styled.div`
  display: flex;
  column-gap: 1rem;
`;

const Container = styled.div<{ $color: string }>`
  padding: 3rem;
  display: flex;
  row-gap: 1.5rem;
  border-radius: 24px;
  height: fit-content;
  flex-direction: column;
  background-color: ${props => props.$color};
`;

export const Stat: React.FC<{ color: 'red' | 'purple'; stats: any; header?: any }> = ({
  color,
  stats,
  header,
}) => {
  return (
    <Container $color={color === 'red' ? RED_100 : PURPLE_100}>
      {!!header && <H4>{header}</H4>}
      {stats?.map((item: any, i: number) => (
        <Row key={i}>
          <Span $color={color === 'red' ? RED_700 : PURPLE_700}>{item.percentage}%</Span>
          <Small>
            {item.description.map((paragraph: any, k: number) => (
              <Fragment key={k}>
                {paragraph.paragraphBold?.length && paragraph.paragraphBold[0] === 'bold' ? (
                  <b>
                    <i>{paragraph.paragraph}</i>
                  </b>
                ) : (
                  <i>{paragraph.paragraph}</i>
                )}
              </Fragment>
            ))}
          </Small>
        </Row>
      ))}
    </Container>
  );
};
