'use client';

import {
  MEDIA_LARGE,
  RED_100,
  RED_500,
  RED_700,
  SIZING_10,
  SIZING_4,
  SIZING_5,
  SIZING_8,
} from '@mnd-frontend/ui';
import { useContext } from 'react';
import { styled } from 'styled-components';
import { scrolledPassedHeroContext } from '../../context';

const H2 = styled.h2`
  line-height: 1;
  max-width: 900px;
  font-weight: 900;
  color: ${RED_700};
  letter-spacing: -0.2rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_10};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_8};
  }
`;

const Span = styled.span`
  line-height: 0.9;
  font-weight: 900;
  color: ${RED_500};
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const Section = styled.section<{ $space: boolean }>`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: ${props => (props.$space ? 'calc(20rem + 86.5px) 0 5rem 0' : '20rem 0 5rem 0')};
  }
  padding: ${props =>
    props.$space ? 'calc(15rem + 86.5px) 2rem 5rem 2rem' : '15rem 2rem 5rem 2rem'};
`;

const Container = styled.div<{ $space: boolean }>`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 0 5rem;
    min-height: ${props => (props.$space ? 'calc(55rem + 86.5px)' : '55rem')};
  }
  min-height: 30rem;
`;

export const Chapter: React.FC<{ subheader: string | null; header: string | null }> = ({
  subheader,
  header,
}) => {
  const { scrolledPassedHero } = useContext(scrolledPassedHeroContext);
  return (
    <Container id="pr-report-chapter-section" $space={scrolledPassedHero}>
      <Section $space={scrolledPassedHero}>
        <Wrapper>
          <Span>{subheader}</Span>
          <H2>{header?.toUpperCase()}</H2>
        </Wrapper>
      </Section>
    </Container>
  );
};
