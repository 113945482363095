'use client';

import {
  MEDIA_LARGE,
  NEUTRAL_WHITE,
  RED_100,
  RED_700,
  SPACING_12,
  SPACING_8,
} from '@mnd-frontend/ui';
import { Fragment } from 'react';
import styled from 'styled-components';
import { H4, P, Tag } from '../ui';

const Author = styled.div<{ $imageUrl: string }>`
  display: flex;
  padding: 2rem;
  min-height: 400px;
  border-radius: 24px;
  justify-content: end;
  flex-direction: column;
  background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5)),
    url(${props => props.$imageUrl});
  background-repeat: no-repeat;
`;

const Card = styled.div`
  color: ${NEUTRAL_WHITE};
`;

const Quote = styled.span`
  margin-top: 0;
  font-size: 5rem;
  line-height: 0.75;
`;

const Blockquote = styled.blockquote`
  display: flex;
  column-gap: 1rem;
  font-weight: 600;
  color: ${RED_700};
  font-size: 1.25rem;
  margin: 4rem 0 0 0;
`;

const H3 = styled.h3`
  opacity: 0.85;
  font-size: 1.45rem;
  margin: 0.5rem 0 1.5rem 0;
`;

const Grid = styled.div`
  display: grid;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Section = styled.section`
  display: flex;
  row-gap: 5rem;
  margin: 0 auto;
  max-width: 1280px;
  flex-direction: column;
`;

const Container = styled.div`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourChapterThree: React.FC<{
  tag: string | null;
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionFourDescription';
        descriptionParagraph: string | null;
      } | null)[]
    | null;
  quotes:
    | ({
        __typename?: 'ModulesFlexibleModulesPrChThreeSectionFourQuotes';
        authorName: string | null;
        authorDescription: string | null;
        authorSize: string | null;
        authorPosition: string | null;
        authorQuote: string | null;
        reversePosition: Array<string | null> | null;
        authorImage: {
          __typename?: 'AcfMediaItemConnectionEdge';
          node: {
            __typename?: 'MediaItem';
            altText: string | null;
            mediaItemUrl: string | null;
          };
        } | null;
      } | null)[]
    | null;
}> = ({ tag, header, description, quotes }) => {
  return (
    <Container>
      <Section>
        <article>
          <Tag $color={RED_100} $backgroundColor={RED_700}>
            {tag?.toUpperCase()}
          </Tag>
          <H3>{header}</H3>
          {description?.map((item, index) => <P key={index}>{item?.descriptionParagraph}</P>)}
        </article>
        <Grid>
          {quotes?.map((item, index) => (
            <Fragment key={index}>
              {item?.reversePosition?.length && item?.reversePosition[0] === 'reverse' ? (
                <>
                  <Blockquote>
                    <Quote>“</Quote>
                    <i>{item.authorQuote}</i>
                  </Blockquote>
                  <Card>
                    <Author $imageUrl={item?.authorImage?.node.mediaItemUrl ?? ''}>
                      <H4>{item.authorName}</H4>
                      <P>{item.authorDescription}</P>
                    </Author>
                  </Card>
                </>
              ) : (
                <>
                  <Card>
                    <Author $imageUrl={item?.authorImage?.node.mediaItemUrl ?? ''}>
                      <H4>{item?.authorName}</H4>
                      <P>{item?.authorDescription}</P>
                    </Author>
                  </Card>
                  <Blockquote>
                    <Quote>“</Quote>
                    <i>{item?.authorQuote}</i>
                  </Blockquote>
                </>
              )}
            </Fragment>
          ))}
        </Grid>
      </Section>
    </Container>
  );
};
