import { ChapterTwoFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne, Takeaways } from '../patterns';
import { SectionFiveChapterTwo } from './section-five-chapter-two';
import { SectionFourChapterTwo } from './section-four-chapter-two';
import { SectionThreeChapterTwo } from './section-three-chapter-two';
import { SectionTwoChapterTwo } from './section-two-chapter-two';

export const ChapterTwo = ({ moduleData }: { moduleData: ChapterTwoFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prChTwoChapterSubheader}
        header={moduleData.prChTwoChapterHeader}
      />
      <SectionOne
        tag={moduleData.prChTwoSectionOneTag}
        description={moduleData.prChTwoSectionOneDescription}
      />
      <SectionTwoChapterTwo
        header={moduleData.prChTwoSectionTwoHeader}
        description={moduleData.prChTwoSectionTwoDescription}
        chartHeader={moduleData.prChTwoSectionTwoChartHeader}
        chart={moduleData.prChTwoSectionTwoChart}
      />
      <SectionThreeChapterTwo
        header={moduleData.prChTwoSectionThreeHeader}
        description={moduleData.prChTwoSectionThreeDescription}
        chartHeader={moduleData.prChTwoSectionThreeChartHeader}
        chart={moduleData.prChTwoSectionThreeChart}
      />
      <SectionFourChapterTwo
        header={moduleData.prChTwoSectionFourHeader}
        description={moduleData.prChTwoSectionFourDescription}
        blueHeader={moduleData.prChTwoSectionFourBlueHeader}
        blueStats={moduleData.prChTwoSectionFourBlueStats}
        redStats={moduleData.prChTwoSectionFourRedStats}
      />
      <SectionFiveChapterTwo
        tag={moduleData.prChTwoSectionFiveTag}
        header={moduleData.prChTwoSectionFiveHeader}
        description={moduleData.prChTwoSectionFiveDescription}
        quote={moduleData.prChTwoSectionFiveQuote}
        list={moduleData.prChTwoSectionFiveParagraphs}
        cardDescription={moduleData.prChTwoSectionFiveCardDescription}
        authorImage={moduleData.prChTwoSectionFiveAuthorImage?.node}
        authorName={moduleData.prChTwoSectionFiveAuthorName}
        authorDescription={moduleData.prChTwoSectionFiveAuthorDescription}
      />
      <Takeaways
        subheader={moduleData.prChTwoTakeawaySubheader}
        title={moduleData.prChTwoTakeawayTitle}
        list={moduleData.prChTwoTakeawayItems}
      />
      <Footer
        subheader={moduleData.prChTwoFooterSubheader}
        header={moduleData.prChTwoFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
