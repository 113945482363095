'use client';

import { MEDIA_LARGE, NEUTRAL_WHITE, RED_100, RED_700, SPACING_3 } from '@mnd-frontend/ui';
import { styled } from 'styled-components';

const Percentage = styled.span`
  font-weight: 600;
  margin-left: auto;
  font-size: 1.15rem;
  color: ${NEUTRAL_WHITE};
`;

const Bar = styled.div<{ $width: string }>`
  display: flex;
  background-color: ${RED_700};
  border-top-right-radius: 4px;
  padding: 0.25rem 1rem 0.25rem 0;
  width: ${props => props.$width};
  border-bottom-right-radius: 4px;
`;

const Column = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  &:before {
    content: '';
    width: 2px;
    height: 50px;
    border-radius: 8px;
    background-color: black;
  }
`;

const Text = styled.p`
  font-size: 1rem;
  text-align: right;
  grid-column: span 2 / span 2;
`;

const Row = styled.div`
  width: 100%;
  display: grid;
  gap: ${SPACING_3};
  align-items: center;
  justify-content: center;
  grid-template-columns: repeat(3, minmax(0, 1fr));
`;

const Card = styled.div`
  display: flex;
  padding: 2rem;
  row-gap: 2rem;
  height: fit-content;
  border-radius: 24px;
  flex-direction: column;
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    position: sticky;
    top: calc(45% - 86.5px);
  }
`;

const H4 = styled.h4`
  margin: 0;
  font-size: 1.15rem;
`;

export const Chart: React.FC<{ header: any; chart: any }> = ({ header, chart }) => {
  return (
    <Card>
      <H4>{header}</H4>
      <div>
        {chart?.map((item: any, index: number) => (
          <Row key={index}>
            <Text>
              <i>{item.description}</i>
            </Text>
            <Column>
              <Bar $width={item.width}>
                <Percentage>{item.percentage}%</Percentage>
              </Bar>
            </Column>
          </Row>
        ))}
      </div>
    </Card>
  );
};
