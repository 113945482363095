import { ChapterOneFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne, Takeaways } from '../patterns';
import { SectionFiveChapterOne } from './section-five-chapter-one';
import { SectionFourChapterOne } from './section-four-chapter-one';
import { SectionSixChapterOne } from './section-six-chapter-one';
import { SectionThreeChapterOne } from './section-three-chapter-one';
import { SectionTwoChapterOne } from './section-two-chapter-one';

export const ChapterOne = ({ moduleData }: { moduleData: ChapterOneFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prChOneChapterSubheader}
        header={moduleData.prChOneChapterHeader}
      />
      <SectionOne
        tag={moduleData.prChOneSectionOneTag}
        description={moduleData.prChOneSectionOneDescription}
      />
      <SectionTwoChapterOne
        header={moduleData.prChOneSectionTwoHeader}
        description={moduleData.prChOneSectionTwoDescription}
        stats={moduleData.prChOneSectionTwoStats}
      />
      <SectionThreeChapterOne
        header={moduleData.prChOneSectionThreeHeader}
        description={moduleData.prChOneSectionThreeDescription}
        stats={moduleData.prChOneSectionThreeStats}
        chartHeader={moduleData.prChOneSectionThreeChartHeader}
        chart={moduleData.prChOneSectionThreeChart}
      />
      <SectionFourChapterOne
        header={moduleData.prChOneSectionFourHeader}
        description={moduleData.prChOneSectionFourDescription}
        chartHeader={moduleData.prChOneSectionFourChartHeader}
        chart={moduleData.prChOneSectionFourChart}
      />
      <SectionFiveChapterOne
        tag={moduleData.prChOneSectionFiveTag}
        header={moduleData.prChOneSectionFiveHeader}
        description={moduleData.prChOneSectionFiveDescription}
        quote={moduleData.prChOneSectionFiveQuote}
        authorImage={moduleData.prChOneSectionFiveAuthorImage?.node}
        authorName={moduleData.prChOneSectionFiveAuthorName}
        authorDescription={moduleData.prChOneSectionFiveAuthorDescription}
        authorSize={moduleData.prChOneSectionFiveAuthorSize}
        authorPosition={moduleData.prChOneSectionFiveAuthorPosition}
      />
      <SectionSixChapterOne
        tag={moduleData.prChOneSectionSixTag}
        header={moduleData.prChOneSectionSixHeader}
        description={moduleData.prChOneSectionSixDescription}
        cardDescription={moduleData.prChOneSectionSixCardDescription}
        quote={moduleData.prChOneSectionSixQuote}
        authorImage={moduleData.prChOneSectionSixAuthorImage?.node}
        authorSize={moduleData.prChOneSectionSixAuthorSize}
        authorMobileSize={moduleData.prChOneSectionSixAuthorMobileSize}
        authorPosition={moduleData.prChOneSectionSixAuthorPosition}
        authorName={moduleData.prChOneSectionSixAuthorName}
        authorDescription={moduleData.prChOneSectionSixAuthorDescription}
        list={moduleData.prChOneSectionSixParagraphs}
      />
      <Takeaways
        subheader={moduleData.prChOneTakeawaySubheader}
        title={moduleData.prChOneTakeawayTitle}
        list={moduleData.prChOneTakeawayItems}
      />
      <Footer
        subheader={moduleData.prChOneFooterSubheader}
        header={moduleData.prChOneFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
