'use client';

import { Button, PURPLE_500, PURPLE_600, Text, useToasts } from '@mnd-frontend/ui';
import { ReCaptchaProvider, useReCaptcha } from 'next-recaptcha-v3';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Checkbox from '../../../../../../../../components/Checkbox';
import { ClientOnlyInput } from '../../../../../../../../components/Input';
import { CACHE_TTL } from '../../../../../../../../constants';
import { track } from '../../../../../../../../lib/tracker';
import { routes } from '../../../../../../../../routes';
import { formatUrl } from '../../../../../../../../utils/formatUrl';
import logger from '../../../../../../../../utils/logger';
import { getSessionStorageKey, setSessionStorageKey } from '../../../../../../../../utils/storage';

const HUBSPOT_BASE_URL = 'https://api.hsforms.com/submissions/v3/integration/submit/';
const HUBSPOT_FORM_IDS = {
  en: 'a5e8da9a-cba3-4261-812c-df850fc31623',
  de: '89b05a24-ce4e-478a-b99e-4b7ee5b9071a',
};
const HUBSPOT_FIELDS_TO_STATE = {
  email: 'email',
} as const;
const FORM_SUBMITTED_SESSION_KEY = 'pr_report_newsletter_subscribed';
const FORM_SUBMITTED_SESSION_VALUE = 'submitted';
const FORM_TEXTS = {
  en: {
    marketingConsent:
      'Yes, I would like to receive marketing communications regarding Mynewsdesk products, services, and events.',
    consentDataUsage:
      'By submitting this form you consent to the storing and processing of your personal data by Mynewsdesk as described in our <link>privacy policy</link>. You can withdraw your consent at any time.',
  },
  'de-de': {
    marketingConsent: 'Mynewsdesk kann mir relevante E-Mails senden, bis ich mich abmelde.',
    consentDataUsage:
      'Mit dem Einsenden des Formulars erklären Sie sich mit der Speicherung und Verarbeitung Ihrer <link>persönlichen Daten einverstanden</link>. Sie können Ihre Einwilligung jederzeit widerrufen.',
  },
};

const Form = styled.form``;

const Fieldset = styled.fieldset`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 0;
`;

const Legend = styled.legend`
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: ${PURPLE_600};
  margin-bottom: 0.5rem;
`;

const InlineLink = styled.a`
  color: ${PURPLE_500};
`;

const SignupForm: React.FC = () => {
  const { executeRecaptcha } = useReCaptcha();
  const { showSuccessToast, showFailureToast } = useToasts();
  const { t, i18n } = useTranslation();
  const lang = i18n.language;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<{
    email?: string[];
  }>({});

  const form_id = HUBSPOT_FORM_IDS.en;

  const trackError = (error_code: string, error_message: string, form_field: string) => {
    track({
      event: 'error_form_submit',
      traits: {
        event_info: {
          form_id,
          error_code,
          error_message,
          form_field,
          form_name: 'Nesletter sign up Pr report',
          navigation_selection: 'Subscribe',
          user_interaction: 'cta_click',
        },
      },
    });
  };

  const handleRecpatchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      logger.error('ReCAPTCHA not available');
      return;
    }
    const token = await executeRecaptcha('NewsletterSubscription');
    return token;
  }, [executeRecaptcha]);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setErrors({});
    setLoading(true);

    const formData = new FormData(e.currentTarget);
    const { email, marketing_communications_opt_in } = Object.fromEntries(formData.entries());
    const token = await handleRecpatchaVerify();

    if (!token) {
      showFailureToast('Failed to verify reCAPTCHA');
      trackError('captcha', 'Failed to verify reCAPTCHA', 'captcha');
      setLoading(false);
      return;
    }
    // verify recaptcha token
    try {
      const verifyRecaptchaResponse = await fetch('/api/verify-captcha', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        next: {
          revalidate: CACHE_TTL,
        },
        body: JSON.stringify({ token }),
      });
      if (!verifyRecaptchaResponse.ok) {
        showFailureToast('Failed to verify recaptcha token');
        setLoading(false);
        trackError('captcha', 'Failed to verify recaptcha token', 'captcha');
        return;
      }
    } catch (e) {
      showFailureToast('Failed to verify recaptcha token');
      setLoading(false);
      trackError('captcha', 'Failed to verify recaptcha token', 'captcha');
      return;
    }

    // submit to hubspot
    const url = `${HUBSPOT_BASE_URL}${process.env.NEXT_PUBLIC_HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_IDS.en}`;
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          fields: [
            { name: 'email', value: email },
            {
              name: 'marketing_communications_opt_in',
              value: marketing_communications_opt_in ? 'Yes' : 'No',
            },
            { name: 'consent_to_processing', value: true },
          ],
        }),
        next: {
          revalidate: CACHE_TTL,
        },
      });

      if (!response.ok) {
        if (response.headers.get('content-type')?.includes('application/json')) {
          const { errors: responseErrors } = await response.json();
          if (responseErrors) {
            const newErrors: typeof errors = {};
            const errorCodes: typeof errors = {};

            responseErrors.forEach((error: { message: string; errorType: string }) => {
              const [field, message] =
                error.message.split("Error in 'fields.")[1]?.split("'. ") ?? [];
              if (field && field in HUBSPOT_FIELDS_TO_STATE && message) {
                const stateFieldError = HUBSPOT_FIELDS_TO_STATE[
                  field as keyof typeof HUBSPOT_FIELDS_TO_STATE
                ] as keyof typeof errors;
                newErrors[stateFieldError] ||= [];
                newErrors[stateFieldError].push(message);
                errorCodes[stateFieldError] ||= [];
                errorCodes[stateFieldError].push(error.errorType);
              }
            });
            const errorEntries = Object.entries(newErrors);
            if (errorEntries.length) {
              setErrors(newErrors);
              errorEntries.forEach(([field, messages]) => {
                const typesafeField = field as keyof typeof errors;
                messages.forEach((message, index) => {
                  trackError(errorCodes[typesafeField]?.[index] ?? '', message, field);
                });
              });
            } else {
              showFailureToast('Failed to submit form');
              trackError('unknown', 'Failed to submit form', 'unknown');
            }
          }
        } else {
          showFailureToast('Failed to submit form');
          trackError('unknown', 'Failed to submit form', 'unknown');
        }
        setLoading(false);
        return;
      }
      showSuccessToast('Subscribed successfully');
      setFormSubmitted(true);
      setSessionStorageKey(FORM_SUBMITTED_SESSION_KEY, FORM_SUBMITTED_SESSION_VALUE);
      setLoading(false);
    } catch (e) {
      logger.error(e);
      showFailureToast('Failed to subscribe');
      setLoading(false);
    }
  };

  useEffect(() => {
    const alreadySubmitted = getSessionStorageKey(FORM_SUBMITTED_SESSION_KEY);
    setFormSubmitted(!!alreadySubmitted);
  }, []);
  return (
    <Form onSubmit={handleSubmit}>
      <Fieldset>
        <Legend>Get notified about webinars, reports and much more!</Legend>
        <ClientOnlyInput
          errors={errors.email}
          name="email"
          type="email"
          label="Email adress"
          required
          prReport
        />
        <Checkbox
          name="marketing_communications_opt_in"
          label={FORM_TEXTS.en.marketingConsent}
          prReport
        />
        <div>
          <Text as="p" variant="bodySmall" color="NEUTRAL_WHITE">
            By submitting this form you consent to the storing and processing of your personal data
            by Mynewsdesk as described in our{' '}
            <InlineLink
              href={formatUrl(routes.consentPolicy(lang))}
              target="_blank"
              rel="noopener noreferrer"
            >
              privacy policy
            </InlineLink>
            . You can withdraw your consent at any time.
          </Text>
        </div>
        <Button
          type="submit"
          aria-label="Subscribe to our newsletter"
          loading={loading}
          disabled={formSubmitted || loading}
        >
          Subscribe
        </Button>
      </Fieldset>
    </Form>
  );
};

export const NewsletterSubscriptionForm: React.FC = () => {
  return (
    <ReCaptchaProvider
      reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || ''}
      async={false}
      defer={false}
      className="optanon-category-C0001"
    >
      <SignupForm />
    </ReCaptchaProvider>
  );
};
