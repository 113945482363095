import { ChapterFiveFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne, Takeaways } from '../patterns';
import { SectionFiveChapterFive } from './section-five-chapter-five';
import { SectionFourChapterFive } from './section-four-chapter-five';
import { SectionSevenChapterFive } from './section-seven-chapter-five';
import { SectionSixChapterFive } from './section-six-chapter-five';
import { SectionThreeChapterFive } from './section-three-chapter-five';
import { SectionTwoChapterFive } from './section-two-chapter-five';

export const ChapterFive = ({ moduleData }: { moduleData: ChapterFiveFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prChFiveChapterSubheader}
        header={moduleData.prChFiveChapterHeader}
      />
      <SectionOne
        tag={moduleData.prChFiveSectionOneTag}
        description={moduleData.prChFiveSectionOneDescription}
      />
      <SectionTwoChapterFive
        header={moduleData.prChFiveSectionTwoHeader}
        description={moduleData.prChFiveSectionTwoDescription}
        stats={moduleData.prChFiveSectionTwoStats}
      />
      <SectionThreeChapterFive
        header={moduleData.prChFiveSectionThreeHeader}
        description={moduleData.prChFiveSectionThreeDescription}
        stats={moduleData.prChFiveSectionThreeStats}
      />
      <SectionFourChapterFive
        header={moduleData.prChFiveSectionFourHeader}
        description={moduleData.prChFiveSectionFourDescription}
        stats={moduleData.prChFiveSectionFourStats}
        chart={moduleData.prChFiveSectionFourChart}
        chartHeader={moduleData.prChFiveSectionFourChartHeader}
      />
      <SectionFiveChapterFive
        tag={moduleData.prChFiveSectionFiveTag}
        header={moduleData.prChFiveSectionFiveHeader}
        description={moduleData.prChFiveSectionFiveDescription}
        quote={moduleData.prChFiveSectionFiveQuote}
        authorImage={moduleData.prChFiveSectionFiveAuthorImage?.node}
        authorName={moduleData.prChFiveSectionFiveAuthorName}
        authorDescription={moduleData.prChFiveSectionFiveAuthorDescription}
        authorSize={moduleData.prChFiveSectionFiveAuthorSize}
        authorPosition={moduleData.prChFiveSectionFiveAuthorPosition}
      />
      <SectionSixChapterFive
        tag={moduleData.prChFiveSectionSixTag}
        header={moduleData.prChFiveSectionSixHeader}
        description={moduleData.prChFiveSectionSixDescription}
        cardDescription={moduleData.prChFiveSectionSixCardDescription}
        quote={moduleData.prChFiveSectionSixQuote}
        authorImage={moduleData.prChFiveSectionSixAuthorImage?.node}
        authorSize={moduleData.prChFiveSectionSixAuthorSize}
        authorMobileSize={moduleData.prChFiveSectionSixAuthorMobileSize}
        authorPosition={moduleData.prChFiveSectionSixAuthorPosition}
        authorName={moduleData.prChFiveSectionSixAuthorName}
        authorDescription={moduleData.prChFiveSectionSixAuthorDescription}
        list={moduleData.prChFiveSectionSixParagraphs}
      />
      <SectionSevenChapterFive
        header={moduleData.prChFiveSectionSevenHeader}
        description={moduleData.prChFiveSectionSevenDescription}
        cardDescription={moduleData.prChFiveSectionSevenCardDescription}
        authorImage={moduleData.prChFiveSectionSevenAuthorImage?.node}
        authorSize={moduleData.prChFiveSectionSevenAuthorSize}
        authorMobileSize={moduleData.prChFiveSectionSevenAuthorMobileSize}
        authorPosition={moduleData.prChFiveSectionSevenAuthorPosition}
        authorName={moduleData.prChFiveSectionSevenAuthorName}
        authorDescription={moduleData.prChFiveSectionSevenAuthorDescription}
        list={moduleData.prChFiveSectionSevenParagraphs}
      />
      <Takeaways
        subheader={moduleData.prChFiveTakeawaySubheader}
        title={moduleData.prChFiveTakeawayTitle}
        list={moduleData.prChFiveTakeawayItems}
      />
      <Footer
        subheader={moduleData.prChFiveFooterSubheader}
        header={moduleData.prChFiveFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
