import { ChapterFourFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne, Takeaways } from '../patterns';
import { SectionEightChapterFour } from './section-eight-chapter-four';
import { SectionFiveChapterFour } from './section-five-chapter-four';
import { SectionFourChapterFour } from './section-four-chapter-four';
import { SectionNineChapterFour } from './section-nine-chapter-four';
import { SectionSevenChapterFour } from './section-seven-chapter-five';
import { SectionSixChapterFour } from './section-six-chapter-four';
import { SectionThreeChapterFour } from './section-three-chapter-four';
import { SectionTwoChapterFour } from './section-two-chapter-four';

export const ChapterFour = ({ moduleData }: { moduleData: ChapterFourFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prChFourChapterSubheader}
        header={moduleData.prChFourChapterHeader}
      />
      <SectionOne
        tag={moduleData.prChFourSectionOneTag}
        description={moduleData.prChFourSectionOneDescription}
      />
      <SectionTwoChapterFour
        header={moduleData.prChFourSectionTwoHeader}
        description={moduleData.prChFourSectionTwoDescription}
        stats={moduleData.prChFourSectionTwoStats}
      />
      <SectionThreeChapterFour
        header={moduleData.prChFourSectionThreeHeader}
        description={moduleData.prChFourSectionThreeDescription}
        stats={moduleData.prChFourSectionThreeStats}
        redStats={moduleData.prChFourSectionThreeRedStats}
      />
      <SectionFourChapterFour
        header={moduleData.prChFourSectionFourHeader}
        description={moduleData.prChFourSectionFourDescription}
        stats={moduleData.prChFourSectionFourRedStats}
        redHeader={moduleData.prChFourSectionFourRedHeader}
      />
      <SectionFiveChapterFour
        header={moduleData.prChFourSectionFiveHeader}
        description={moduleData.prChFourSectionFiveDescription}
        blueStats={moduleData.prChFourSectionFiveBlueStats}
        redStats={moduleData.prChFourSectionFiveRedStats}
      />
      <SectionSixChapterFour
        header={moduleData.prChFourSectionSixHeader}
        description={moduleData.prChFourSectionSixDescription}
        stats={moduleData.prChFourSectionSixStats}
        chart={moduleData.prChFourSectionSixChart}
        chartHeader={moduleData.prChFourSectionSixChartHeader}
      />
      <SectionSevenChapterFour
        tag={moduleData.prChFourSectionSevenTag}
        header={moduleData.prChFourSectionSevenHeader}
        description={moduleData.prChFourSectionSevenDescription}
        quote={moduleData.prChFourSectionSevenQuote}
        authorImage={moduleData.prChFourSectionSevenAuthorImage?.node}
        authorName={moduleData.prChFourSectionSevenAuthorName}
        authorDescription={moduleData.prChFourSectionSevenAuthorDescription}
        authorSize={moduleData.prChFourSectionSevenAuthorSize}
        authorPosition={moduleData.prChFourSectionSevenAuthorPosition}
      />
      <SectionEightChapterFour
        tag={moduleData.prChFourSectionEightTag}
        header={moduleData.prChFourSectionEightHeader}
        description={moduleData.prChFourSectionEightDescription}
        quote={moduleData.prChFourSectionEightQuote}
        authorImage={moduleData.prChFourSectionEightAuthorImage?.node}
        authorSize={moduleData.prChFourSectionEightAuthorSize}
        authorMobileSize={moduleData.prChFourSectionEightAuthorMobileSize}
        authorPosition={moduleData.prChFourSectionEightAuthorPosition}
        authorName={moduleData.prChFourSectionEightAuthorName}
        authorDescription={moduleData.prChFourSectionEightAuthorDescription}
        list={moduleData.prChFourSectionEightParagraphs}
      />
      <SectionNineChapterFour
        subheader={moduleData.prChFourSectionNineSubheader}
        header={moduleData.prChFourSectionNineHeader}
        list={moduleData.prChFourSectionNineParagraphs}
      />
      <Takeaways
        subheader={moduleData.prChFourTakeawaySubheader}
        title={moduleData.prChFourTakeawayTitle}
        list={moduleData.prChFourTakeawayItems}
      />
      <Footer
        subheader={moduleData.prChFourFooterSubheader}
        header={moduleData.prChFourFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
