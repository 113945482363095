'use client';

import { useMediaQuery } from '@mnd-frontend/hooks';
import { Icons, MEDIA_LARGE, NEUTRAL_WHITE, RED_700, SPACING_4, SPACING_6 } from '@mnd-frontend/ui';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { useEffect, useState } from 'react';
import { styled } from 'styled-components';
import { SrOnly } from '../../../../../../../components/SrOnly';
import { track } from '../../../../../../../lib/tracker';

const Links = styled.div`
  display: flex;
  row-gap: 2rem;
  text-align: center;
  flex-direction: column;
  justify-content: center;
`;

const Overlay = styled.div<{ $open: boolean }>`
  position: absolute;
  row-gap: 5rem;
  flex-direction: column;
  top: calc(30vh - 86.5px);
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 400ms;
  display: ${props => (props.$open ? 'flex' : 'none')};
`;

const MenuBar = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Menu = styled.button`
  cursor: pointer;
  background-color: transparent;
`;

const Li = styled.li<{ $active: boolean }>`
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke,
    opacity, box-shadow, transform, filter, backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 300ms;
  opacity: ${props => (props.$active ? 1 : 0.5)};
  &:hover {
    ${props => !props.$active && 'opacity: 1'}
  }
`;

const Ul = styled.ul`
  margin: 0;
  padding: 0;
  display: flex;
  list-style: none;
  align-items: center;
  column-gap: ${SPACING_6};
  @media (max-width: ${MEDIA_LARGE}px) {
    row-gap: 1rem;
    flex-direction: column;
  }
`;

const MobileNav = styled.nav<{ $open: boolean; $heroShown: boolean; $scrolledPassedHero: boolean }>`
  position: ${({ $heroShown, $scrolledPassedHero }) =>
    $heroShown ? ($scrolledPassedHero ? 'fixed' : 'static') : 'fixed'};
  top: 0;
  z-index: 20;
  display: flex;
  width: 100%;
  font-size: 1.5rem;
  align-items: center;
  color: ${NEUTRAL_WHITE};
  padding: ${SPACING_4};
  flex-direction: column;
  background-color: ${RED_700};
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 200ms;
  height: ${props => (props.$open ? '100vh' : '98px')};
`;

const DesktopNav = styled.nav<{
  $heroShown: boolean;
  $scrolledPassedHero: boolean;
}>`
  position: ${({ $heroShown, $scrolledPassedHero }) =>
    $heroShown ? ($scrolledPassedHero ? 'fixed' : 'static') : 'fixed'};
  top: 0;
  z-index: 20;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${SPACING_4};
  background-color: ${RED_700};
  justify-content: space-between;
  color: ${NEUTRAL_WHITE};
`;

export const Navbar: React.FC<{
  navigation:
    | ({
        __typename?: 'ModulesFlexibleModulesNavigationItems';
        link: {
          __typename?: 'AcfLink';
          url: string | null;
          title: string | null;
        } | null;
      } | null)[]
    | null;
  downloadableUrl: string | null;
  heroShown: boolean;
  scrolledPassedHero: boolean;
}> = ({ navigation, downloadableUrl, heroShown, scrolledPassedHero }) => {
  const pathname = usePathname();
  const [open, setOpen] = useState(false);
  const isMobile = useMediaQuery(`@media (max-width: ${MEDIA_LARGE}px)`);

  useEffect(() => {
    if (!isMobile) return;
    if (open) {
      document.body.classList.add('block-scrolling');
      return () => {
        document.body.classList.remove('block-scrolling');
      };
    }
    return;
  }, [open, isMobile]);

  return (
    <>
      {isMobile ? (
        <MobileNav $open={open} $heroShown={heroShown} $scrolledPassedHero={scrolledPassedHero}>
          <MenuBar>
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={22} fill="none">
              <path
                fill="#fff"
                d="M25.829 2.316C24.323.811 22.354 0 19.69 0c-2.316 0-4.285.58-5.675 1.853C12.509.695 10.655 0 8.339 0 5.791 0 3.706.81 2.201 2.316.695 3.822 0 5.676 0 7.876v13.55h5.328V8.456c0-1.158.232-1.97.81-2.432.58-.464 1.275-.695 2.085-.695.811 0 1.506.231 2.085.695.695.463 1.043 1.274 1.043 2.432v12.972h5.328V8.455c0-1.158.231-1.97.81-2.432.58-.464 1.274-.695 2.085-.695.811 0 1.506.231 2.085.695.58.463.81 1.274.81 2.432v12.972h5.329V7.876c0-2.344-.58-4.054-1.97-5.56Z"
              />
            </svg>
            <Menu type="button" onClick={() => setOpen(!open)}>
              {open ? (
                <Icons.Close $color="NEUTRAL_WHITE" $size="sm" />
              ) : (
                <Icons.Menu $color="NEUTRAL_WHITE" $size="sm" />
              )}
            </Menu>
          </MenuBar>
          <Overlay $open={open}>
            <Ul>
              {navigation?.map((item, index) => {
                const active = item?.link?.url?.includes(pathname) ?? false;
                return (
                  <Li key={index} $active={active} aria-current={active ? 'page' : undefined}>
                    <Link href={item?.link?.url ?? '#'}>{item?.link?.title}</Link>
                  </Li>
                );
              })}
            </Ul>
            <Links>
              <Link
                href="/"
                onClick={() =>
                  track({
                    event: 'link_click',
                    traits: { event_info: { link_title: 'Go to Mynewsdesk' } },
                  })
                }
              >
                Go to mynewsdesk
              </Link>
              <Link
                href={downloadableUrl ?? '#'}
                onClick={() =>
                  track({
                    event: 'file_download',
                    traits: {
                      event_info: {
                        file_name: 'pr_report_2025',
                        file_type: 'pdf',
                      },
                    },
                  })
                }
              >
                Download PDF
              </Link>
            </Links>
          </Overlay>
        </MobileNav>
      ) : (
        <DesktopNav $heroShown={heroShown} $scrolledPassedHero={scrolledPassedHero}>
          <Link
            href="/"
            onClick={() =>
              track({
                event: 'link_click',
                traits: { event_info: { link_title: 'Go to Mynewsdesk' } },
              })
            }
          >
            <svg xmlns="http://www.w3.org/2000/svg" width={28} height={22} fill="none">
              <path
                fill="#fff"
                d="M25.829 2.316C24.323.811 22.354 0 19.69 0c-2.316 0-4.285.58-5.675 1.853C12.509.695 10.655 0 8.339 0 5.791 0 3.706.81 2.201 2.316.695 3.822 0 5.676 0 7.876v13.55h5.328V8.456c0-1.158.232-1.97.81-2.432.58-.464 1.275-.695 2.085-.695.811 0 1.506.231 2.085.695.695.463 1.043 1.274 1.043 2.432v12.972h5.328V8.455c0-1.158.231-1.97.81-2.432.58-.464 1.274-.695 2.085-.695.811 0 1.506.231 2.085.695.58.463.81 1.274.81 2.432v12.972h5.329V7.876c0-2.344-.58-4.054-1.97-5.56Z"
              />
            </svg>{' '}
            <SrOnly>website</SrOnly>
          </Link>
          <Ul>
            {navigation?.map((item, index) => {
              const active = item?.link?.url?.includes(pathname) ?? false;
              return (
                <Li key={index} $active={active} aria-current={active ? 'page' : undefined}>
                  <Link href={item?.link?.url ?? '#'}>{item?.link?.title}</Link>
                </Li>
              );
            })}
          </Ul>
          <Link
            href={downloadableUrl ?? '#'}
            onClick={() =>
              track({
                event: 'file_download',
                traits: {
                  event_info: {
                    file_name: 'pr_report_2025',
                    file_type: 'pdf',
                  },
                },
              })
            }
            style={{
              border: '1px solid white',
              borderRadius: '360px',
              padding: '0.5rem 1rem',
              display: 'flex',
              alignItems: 'center',
              columnGap: '0.5rem',
            }}
          >
            <Icons.Download $size="xxs" />
            Download PDF <SrOnly>version of the PR report</SrOnly>
          </Link>
        </DesktopNav>
      )}
    </>
  );
};
