'use client';

import {
  MEDIA_LARGE,
  NEUTRAL_WHITE,
  RED_100,
  RED_500,
  RED_700,
  SIZING_14,
  SIZING_4,
  SIZING_5,
  SIZING_6,
  SIZING_7,
  SPACING_12,
  SPACING_6,
  SPACING_8,
} from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { H4, Ol, OlLi, P } from '../ui';

const H3 = styled.h3`
  margin: 0;
  line-height: 1;
  font-weight: 900;
  font-size: ${SIZING_14};
`;

const Card = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  border-radius: 24px;
  padding: 5rem 2.5rem;
  row-gap: ${SPACING_6};
  flex-direction: column;
  color: ${NEUTRAL_WHITE};
  justify-content: center;
  background-color: ${RED_700};
  @media (min-width: ${MEDIA_LARGE}px) {
    max-width: 620px;
    margin-top: -12rem;
  }
`;

const I = styled.i`
  color: ${RED_700};
`;

const Wrapper = styled.div`
  display: flex;
  row-gap: 1rem;
  flex-direction: column;
`;

const H2 = styled.h2`
  line-height: 1.15;
  color: ${RED_700};
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_7};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_6};
  }
`;

const Span = styled.span`
  line-height: 0.9;
  font-weight: 700;
  color: ${RED_500};
  @media (min-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_5};
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    font-size: ${SIZING_4};
  }
`;

const Article = styled.article`
  display: flex;
  row-gap: 1.5rem;
  flex-direction: column;
`;

const Section = styled.section`
  display: grid;
  margin: 0 auto;
  max-width: 1280px;
  @media (min-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_12};
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    gap: ${SPACING_8};
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

const Container = styled.div`
  background-color: ${RED_100};
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionTwoIntro: React.FC<{
  header: string | null;
  description: string | null;
  statement: string | null;
  subheader: string | null;
  statPercentage: number | null;
  statParagraphs:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionTwoStatParagraphs';
        paragraph: string | null;
        paragraphBold: Array<string | null> | null;
      } | null)[]
    | null;
  list:
    | ({
        __typename?: 'ModulesFlexibleModulesPrInSectionTwoList';
        header: string | null;
        description: string | null;
      } | null)[]
    | null;
}> = ({ header, description, statement, subheader, statPercentage, statParagraphs, list }) => {
  return (
    <Container>
      <Section>
        <Article>
          <header>
            <Span>{subheader}</Span>
            <H2>
              <i>{header}</i>
            </H2>
          </header>
          <P>{description}</P>
          <P>
            <b>{statement}</b>
          </P>
        </Article>
        <Ol>
          {list?.map((item, index) => (
            <OlLi key={index}>
              <Wrapper>
                <H4>
                  Dealing with <I>{item?.header}</I>
                </H4>
                <P>{item?.description}</P>
              </Wrapper>
            </OlLi>
          ))}
        </Ol>
        <Card>
          <H3>{statPercentage}%</H3>
          <P>
            {statParagraphs?.map((item, i) => (
              <Fragment key={i}>
                {item?.paragraphBold?.length && item?.paragraphBold[0] === 'bold' ? (
                  <b>{item?.paragraph}</b>
                ) : (
                  <>{item?.paragraph}</>
                )}
              </Fragment>
            ))}
          </P>
        </Card>
      </Section>
    </Container>
  );
};
