import { ChapterThreeFragment } from '../../../../../../../wp-generated/types';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne, Takeaways } from '../patterns';
import { SectionFiveChapterThree } from './section-five-chapter-three';
import { SectionFourChapterThree } from './section-four-chapter-three';
import { SectionThreeChapterThree } from './section-three-chapter-three';
import { SectionTwoChapterThree } from './section-two-chapter-three';

export const ChapterThree = ({ moduleData }: { moduleData: ChapterThreeFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter
        subheader={moduleData.prChThreeChapterSubheader}
        header={moduleData.prChThreeChapterHeader}
      />
      <SectionOne
        tag={moduleData.prChThreeSectionOneTag}
        description={moduleData.prChThreeSectionOneDescription}
      />
      <SectionTwoChapterThree
        header={moduleData.prChThreeSectionTwoHeader}
        description={moduleData.prChThreeSectionTwoDescription}
        stats={moduleData.prChThreeSectionTwoStats}
      />
      <SectionThreeChapterThree
        header={moduleData.prChThreeSectionThreeHeader}
        description={moduleData.prChThreeSectionThreeDescription}
        blueStats={moduleData.prChThreeSectionThreeBlueStats}
        redStats={moduleData.prChThreeSectionThreeRedStats}
      />
      <SectionFourChapterThree
        tag={moduleData.prChThreeSectionFourTag}
        header={moduleData.prChThreeSectionFourHeader}
        description={moduleData.prChThreeSectionFourDescription}
        quotes={moduleData.prChThreeSectionFourQuotes}
      />
      <SectionFiveChapterThree
        tag={moduleData.prChThreeSectionFiveTag}
        header={moduleData.prChThreeSectionFiveHeader}
        description={moduleData.prChThreeSectionFiveDescription}
        cardDescription={moduleData.prChThreeSectionFiveCardDescription}
        quote={moduleData.prChThreeSectionFiveQuote}
        authorImage={moduleData.prChThreeSectionFiveAuthorImage?.node}
        authorSize={moduleData.prChThreeSectionFiveAuthorSize}
        authorMobileSize={moduleData.prChThreeSectionFiveAuthorMobileSize}
        authorPosition={moduleData.prChThreeSectionFiveAuthorPosition}
        authorName={moduleData.prChThreeSectionFiveAuthorName}
        authorDescription={moduleData.prChThreeSectionFiveAuthorDescription}
        list={moduleData.prChThreeSectionFiveParagraphs}
      />
      <Takeaways
        subheader={moduleData.prChThreeTakeawaySubheader}
        title={moduleData.prChThreeTakeawayTitle}
        list={moduleData.prChThreeTakeawayItems}
      />
      <Footer
        subheader={moduleData.prChThreeFooterSubheader}
        header={moduleData.prChThreeFooterHeader}
        link={moduleData.link}
      />
    </>
  );
};
