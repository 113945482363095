'use client';

import { MEDIA_LARGE } from '@mnd-frontend/ui';
import { Fragment } from 'react';
import { styled } from 'styled-components';
import { Article, H2, P } from '../ui';

const Section = styled.section`
  margin: 0 auto;
  max-width: 1280px;
`;

const Container = styled.div`
  @media (min-width: ${MEDIA_LARGE}px) {
    padding: 10rem 5rem 5rem 5rem;
  }
  @media (max-width: ${MEDIA_LARGE}px) {
    padding: 5rem 2rem;
  }
`;

export const SectionFourSummary: React.FC<{
  header: string | null;
  description:
    | ({
        __typename?: 'ModulesFlexibleModulesPrSuSectionFourDescription';
        paragraphs: Array<{
          __typename?: 'ModulesFlexibleModulesPrSuSectionFourDescriptionParagraphs';
          paragraph: string | null;
          paragraphBold: Array<string | null> | null;
        } | null> | null;
      } | null)[]
    | null;
}> = ({ header, description }) => {
  return (
    <Container>
      <Section>
        <Article>
          <H2>{header}</H2>
          {description?.map((item, i) => (
            <P key={i}>
              {item?.paragraphs?.map((text, k) => (
                <Fragment key={k}>
                  {text?.paragraphBold?.length && text?.paragraphBold[0] === 'bold' ? (
                    <b>{text?.paragraph}</b>
                  ) : (
                    <>{text?.paragraph}</>
                  )}
                </Fragment>
              ))}
            </P>
          ))}
        </Article>
      </Section>
    </Container>
  );
};
