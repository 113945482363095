'use client';

import { MEDIA_LARGE, NEUTRAL_WHITE, PURPLE_1000, Text } from '@mnd-frontend/ui';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { NewsletterSubscriptionForm } from './newsletter-subscription-form';

const StyledFooter = styled.footer`
  background-color: ${PURPLE_1000};
  color: ${NEUTRAL_WHITE};
  display: flex;
  align-items: center;
`;

const Container = styled.div`
  display: grid;
  justify-content: center;
  align-items: center;
  grid-template-columns: 1fr;
  grid-template-rows: auto 1fr;
  padding: 4rem 1.5rem;
  width: 100%;
  margin: 0 auto;
  gap: 2rem;
  @media (min-width: ${MEDIA_LARGE}px) {
    max-width: 1200px;
    grid-template-rows: unset;
    grid-template-columns: 60% 1fr;
    padding: 4rem;
  }
`;

const Info = styled.div``;

export const SummaryFooter: React.FC = () => {
  const { t, i18n } = useTranslation();
  const lang = i18n.language;

  return (
    <StyledFooter>
      <Container>
        <Info>
          <Text as="h3" variant="headlineSmall" color="PURPLE_600">
            Interested in other reports from us?
          </Text>
          <Text as="h2" variant="displayMedium" color="NEUTRAL_WHITE">
            Subscribe to our newsletter
          </Text>
        </Info>
        <NewsletterSubscriptionForm />
      </Container>
    </StyledFooter>
  );
};
