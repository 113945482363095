import { SummaryFragment } from '../../../../../../../wp-generated/types';
import { Header, SummaryFooter } from '../navigation';
import { Chapter, SectionOne } from '../patterns';
import { SectionEightSummary } from './section-eight-summary';
import { SectionFiveSummary } from './section-five-summary';
import { SectionFourSummary } from './section-four-summary';
import { SectionSevenSummary } from './section-seven-summary';
import { SectionSixSummary } from './section-six-summary';
import { SectionThreeSummary } from './section-three-summary';
import { SectionTwoSummary } from './section-two-summary';

export const PrReportSummary = ({ moduleData }: { moduleData: SummaryFragment }) => {
  return (
    <>
      <Header
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter subheader={moduleData.prSuChapterSubheader} header={moduleData.prSuChapterHeader} />
      <SectionOne
        tag={moduleData.prSuSectionOneTag}
        description={moduleData.prSuSectionOneDescription}
      />
      <SectionTwoSummary
        header={moduleData.prSuSectionTwoHeader ?? ''}
        description={moduleData.prSuSectionTwoDescription}
      />
      <SectionThreeSummary
        header={moduleData.prSuSectionThreeHeader ?? ''}
        description={moduleData.prSuSectionThreeDescription}
        quote={moduleData.prSuSectionThreeQuote ?? ''}
        authorSize={moduleData.prSuSectionThreeAuthorSize ?? ''}
        authorPosition={moduleData.prSuSectionThreeAuthorPosition ?? ''}
        authorImage={moduleData.prSuSectionThreeAuthorImage?.node}
        authorName={moduleData.prSuSectionThreeAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionThreeAuthorDescription ?? ''}
      />
      <SectionFourSummary
        header={moduleData.prSuSectionFourHeader ?? ''}
        description={moduleData.prSuSectionFourDescription}
      />
      <SectionFiveSummary
        header={moduleData.prSuSectionFiveHeader ?? ''}
        description={moduleData.prSuSectionFiveDescription}
        quote={moduleData.prSuSectionFiveQuote ?? ''}
        authorSize={moduleData.prSuSectionFiveAuthorSize ?? ''}
        authorPosition={moduleData.prSuSectionFiveAuthorPosition ?? ''}
        authorImage={moduleData.prSuSectionFiveAuthorImage?.node}
        authorName={moduleData.prSuSectionFiveAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionFiveAuthorDescription ?? ''}
      />
      <SectionSixSummary
        header={moduleData.prSuSectionSixHeader ?? ''}
        itemHeader={moduleData.prSuSectionSixItemHeader ?? ''}
        description={moduleData.prSuSectionSixDescription}
        list={moduleData.prSuSectionSixItems}
      />
      <SectionSevenSummary
        header={moduleData.prSuSectionSevenHeader ?? ''}
        description={moduleData.prSuSectionSevenDescription}
        quote={moduleData.prSuSectionSevenQuote ?? ''}
        authorSize={moduleData.prSuSectionSevenAuthorSize ?? ''}
        authorPosition={moduleData.prSuSectionSevenAuthorPosition ?? ''}
        authorImage={moduleData.prSuSectionSevenAuthorImage?.node}
        authorName={moduleData.prSuSectionSevenAuthorName ?? ''}
        authorDescription={moduleData.prSuSectionSevenAuthorDescription ?? ''}
      />
      <SectionEightSummary
        header={moduleData.prSuSectionEightHeader ?? ''}
        description={moduleData.prSuSectionEightDescription}
      />
      <SummaryFooter />
    </>
  );
};
