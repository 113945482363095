import { IntroFragment } from '../../../../../../../wp-generated/types';
import { ScrolledPassedHeroProvider } from '../../context';
import { Footer, Header } from '../navigation';
import { Chapter, SectionOne } from '../patterns';
import { SectionFourIntro } from './section-four-intro';
import { SectionThreeIntro } from './section-three-intro';
import { SectionTwoIntro } from './section-two-intro';

export const PrReportIntro = ({
  moduleData,
  hero,
}: {
  moduleData: IntroFragment;
  hero?: {
    heroBottomText: string | null;
    heroTopText: string | null;
  } | null;
}) => {
  return (
    <ScrolledPassedHeroProvider>
      <Header
        bottomText={hero?.heroBottomText}
        topText={hero?.heroTopText}
        navigation={moduleData.navigationItems}
        downloadableUrl={moduleData.downloadableUrl}
      />
      <Chapter subheader={moduleData.prInChapterSubheader} header={moduleData.prInChapterHeader} />
      <SectionOne
        tag={moduleData.prInSectionOneTag}
        description={moduleData.prInSectionOneDescription}
      />
      <SectionTwoIntro
        header={moduleData.prInSectionTwoHeader}
        description={moduleData.prInSectionTwoDescription}
        statement={moduleData.prInSectionTwoStatement}
        subheader={moduleData.prInSectionTwoSubheader}
        statPercentage={moduleData.prInSectionTwoStatPercentage}
        statParagraphs={moduleData.prInSectionTwoStatParagraphs}
        list={moduleData.prInSectionTwoList}
      />
      <SectionThreeIntro
        tag={moduleData.prInSectionThreeTag}
        header={moduleData.prInSectionThreeHeader}
        list={moduleData.prInSectionThreeList}
      />
      <SectionFourIntro
        tag={moduleData.prInSectionFourTag}
        experts={moduleData.prInSectionFourExperts}
      />
      <Footer
        subheader={moduleData.prInFooterSubheader}
        header={moduleData.prInFooterHeader}
        link={moduleData.link}
      />
    </ScrolledPassedHeroProvider>
  );
};
