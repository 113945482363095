'use client';

import {
  Icons,
  MynewsdeskLogo,
  NEUTRAL_WHITE,
  SPACING_2,
  SPACING_3,
  SPACING_4,
  SPACING_6,
  Text,
} from '@mnd-frontend/ui';
import { useContext, useEffect, useRef } from 'react';
import { styled } from 'styled-components';
import { scrolledPassedHeroContext } from '../../context';
import { Navbar } from './navbar';

const Article = styled.article`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const H1 = styled.h1`
  display: flex;
  font-weight: 900;
  font-size: 1.75rem;
  flex-direction: column;
  color: ${NEUTRAL_WHITE};
`;

const Hero = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background-position: center;
  background-color: rgba(23, 21, 70, 1);
  padding: ${SPACING_6} ${SPACING_4};
`;

const InfoBox = styled.div`
  background-color: rgba(255, 255, 255, 0.1);
  color: ${NEUTRAL_WHITE};
  padding: ${SPACING_3};
  border-radius: ${SPACING_2};
  margin-top: ${SPACING_4};
  margin-bottom: ${SPACING_4};
`;

const Logo: React.FC = () => {
  return (
    <svg
      width="328"
      height="366"
      viewBox="0 0 378 416"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>PR</title>
      <desc>Logo for PR</desc>
      <path
        d="M265.989 415.103H147.13V206.02H289.656C307.286 206.02 322.017 211.574 333.851 222.441C345.684 233.309 351.721 247.316 351.721 264.462C351.721 285.472 341.337 303.343 324.191 313.969L377.304 415.051H326.348L282.411 323.629H265.989V415.103ZM265.989 245.142V284.507H284.826C296.418 284.507 304.629 277.02 304.629 264.462C304.629 252.146 296.418 245.142 284.826 245.142H265.989Z"
        fill="#FFD2DE"
      />
      <path
        d="M133.499 414.725H21.561V0L133.499 0L275.353 0C330.955 0 375.986 39.94 375.986 95.5428C377.161 149.971 328.998 193.827 275.353 192.26H133.499V414.725ZM133.499 63.4341V128.826H267.521C286.317 128.826 299.63 115.904 299.63 95.5428C299.63 75.5728 286.317 63.4341 267.521 63.4341L133.499 63.4341Z"
        fill="#FF4473"
      />
      <rect width="75.785" height="414.725" fill="#FF4473" />
    </svg>
  );
};

export const Header: React.FC<{
  bottomText?: string | null;
  topText?: string | null;
  navigation:
    | ({
        __typename?: 'ModulesFlexibleModulesNavigationItems';
        link: {
          __typename?: 'AcfLink';
          url: string | null;
          title: string | null;
        } | null;
      } | null)[]
    | null;
  downloadableUrl: string | null;
  header?: string | null;
  description?: string | null;
}> = ({ bottomText, topText, navigation, downloadableUrl, header, description }) => {
  const heroRef = useRef<HTMLDivElement>(null);
  const { scrolledPassedHero, setScrolledPassedHero } = useContext(scrolledPassedHeroContext);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setScrolledPassedHero(!entry.isIntersecting),
      {
        threshold: 0,
      },
    );

    if (heroRef.current) {
      observer.observe(heroRef.current);
    }

    return () => {
      if (heroRef.current) {
        observer.unobserve(heroRef.current);
      }
    };
  }, []);

  return (
    <header>
      {bottomText && topText && (
        <Hero ref={heroRef}>
          <MynewsdeskLogo width="229" aria-hidden="true" focusable="false" />
          <div>
            <Article>
              <H1>
                {topText} <Logo />
                {bottomText}
              </H1>
            </Article>
            <InfoBox>
              <Text as="p" variant="headlineMedium" marginBottom="SPACING_2">
                {header}
              </Text>
              <Text as="p" variant="body" color="PURPLE_500">
                {description}
              </Text>
            </InfoBox>
          </div>
          <a href="#pr-report-chapter-section" aria-label="Scroll down to start of report">
            <Icons.ChevronDown $size="md" $color="NEUTRAL_WHITE" aria-hidden="true" />
          </a>
        </Hero>
      )}
      <Navbar
        navigation={navigation}
        downloadableUrl={downloadableUrl}
        heroShown={!!(bottomText && topText)}
        scrolledPassedHero={scrolledPassedHero}
      />
    </header>
  );
};
